import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import * as ss58 from '@chainflip/utils/ss58';
import classNames from 'classnames';
import PersonHero from '@/shared/assets/svg/modal-person.svg';
import { Button } from '@/shared/components';
import { DashboardModal } from '@/shared/components/dashboards/Modal';
import { type ChainflipNetwork, getChainflipNetwork } from '@/shared/utils';

const defaultAccountIds: Partial<Record<ChainflipNetwork, string>> = {
  backspin: 'cFKSFcF57NfUVuwLPkCmQ2vF11RQ28gPn7yiLnnTswUtmVcAu', // niklas
  sisyphos: 'cFKSFcF57NfUVuwLPkCmQ2vF11RQ28gPn7yiLnnTswUtmVcAu', // niklas
  perseverance: 'cFJ4ELogY7gXLueJGZaQUyTC8582ntbKtNw1V7arieyv1Wk5Z', // idk? but its the only account with a boost position
  mainnet: 'cFLTUvyYwfZBLjCuLP6G8t85qW3FDmzSD3WPYq249EhP3Zs9g', // david
};
export const defaultAccountId = defaultAccountIds[getChainflipNetwork()];

const ViewOnlyAccountModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [accountId, setAccountId] = useState(defaultAccountId ?? '');
  const router = useRouter();
  const hasError = useMemo(() => {
    if (accountId === '') return false;

    try {
      ss58.decode(accountId);
      return false;
    } catch {
      return true;
    }
  }, [accountId]);

  return (
    <DashboardModal open={open} onClose={onClose}>
      <div className="bg-holy-radial-gray-3-60 relative w-[32rem] rounded-md border border-cf-gray-4 backdrop-blur-[6px]">
        <div className="flex h-full flex-col justify-between space-y-10 p-5">
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <span className="cf-gray-gradient font-aeonikMedium text-20">View-only mode</span>
              <div className="max-w-xs text-14 text-cf-light-2">
                Enter a Chainflip account ID to try out the liquidity provision app in view-only
                mode.
              </div>
            </div>
            <PersonHero className="pointer-events-none fixed right-0 top-0" />
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="relative flex flex-col">
              <input
                placeholder="cF..."
                type="text"
                className={classNames(
                  'rounded-md border border-cf-gray-4 bg-cf-gray-2 px-3 py-2 font-aeonikMono text-14 text-white outline-none transition placeholder:text-cf-light-2 hover:bg-cf-gray-3 disabled:cursor-not-allowed disabled:hover:bg-cf-gray-2',
                  hasError && 'border-cf-red-1',
                )}
                value={accountId}
                onChange={(e) => setAccountId(e.target.value)}
                spellCheck={false}
              />
              {hasError && (
                <span className="absolute -bottom-6 text-12 text-cf-red-1">
                  Enter a valid account ID
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center gap-x-2 self-end justify-self-end">
            <Button className="max-w-fit" type="secondary-standard" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              className="max-w-fit"
              type="primary-standard"
              onClick={() => {
                router.replace({ query: { ...router.query, accountId } });
                onClose();
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </DashboardModal>
  );
};

export default ViewOnlyAccountModal;
